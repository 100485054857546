import restApi from "@/plugins/http";

export class Api {
  execute(method, resource, data, config = null) {
    return new Promise((resolve, reject) => {
      restApi[method](`/api/v1/${resource}`, data, config)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getClassifications(data) {
    return this.execute("get", "workspace/classifications", data);
  }
  createClassifications(data) {
    return this.execute("post", "workspace/classifications", data);
  }
  readClassifications(id, data) {
    return this.execute("get", `workspace/classifications/${id}`, data);
  }
  getClassificationsChildren(id, data) {
    return this.execute(
      "get",
      `workspace/classifications/children/${id}`,
      data
    );
  }
  updateClassifications(id, data) {
    return this.execute("put", `workspace/classifications/${id}`, data);
  }
  deleteClassifications(id) {
    return this.execute("delete", `workspace/classifications/${id}`);
  }

  getBrands(data) {
    return this.execute("get", "workspace/brands", data);
  }
  createBrands(data) {
    return this.execute("post", "workspace/brands", data);
  }
  readBrands(id, data) {
    return this.execute("get", `workspace/brands/${id}`, data);
  }
  updateBrands(id, data) {
    return this.execute("put", `workspace/brands/${id}`, data);
  }
  deleteBrands(id) {
    return this.execute("delete", `workspace/brands/${id}`);
  }

  getModels(data) {
    return this.execute("get", "workspace/models", data);
  }
  createModels(data) {
    return this.execute("post", "workspace/models", data);
  }
  readModels(id, data) {
    return this.execute("get", `workspace/models/${id}`, data);
  }
  updateModels(id, data) {
    return this.execute("put", `workspace/models/${id}`, data);
  }
  deleteModels(id) {
    return this.execute("delete", `workspace/models/${id}`);
  }

  getParameters(data) {
    return this.execute("get", "workspace/parameters", data);
  }
  createParameters(data) {
    return this.execute("post", "workspace/parameters", data);
  }
  readParameters(id, data) {
    return this.execute("get", `workspace/parameters/${id}`, data);
  }
  updateParameters(id, data) {
    return this.execute("put", `workspace/parameters/${id}`, data);
  }
  deleteParameters(id) {
    return this.execute("delete", `workspace/parameters/${id}`);
  }
  getValues(data) {
    return this.execute("get", "workspace/values", data);
  }
  createValues(data) {
    return this.execute("post", "workspace/values", data);
  }
  readValues(id, data) {
    return this.execute("get", `workspace/values/${id}`, data);
  }
  updateValues(id, data) {
    return this.execute("put", `workspace/values/${id}`, data);
  }
  deleteValues(id) {
    return this.execute("delete", `workspace/values/${id}`);
  }

  /* Catalog */
  getCatalogs(data) {
    return this.execute("get", "workspace/catalogs", data);
  }
  createCatalogs(data) {
    return this.execute("post", "workspace/catalogs", data);
  }
  readCatalogs(id, data) {
    return this.execute("get", `workspace/catalogs/${id}`, data);
  }
  updateCatalogs(id, data) {
    return this.execute("put", `workspace/catalogs/${id}`, data);
  }
  deleteCatalogs(id) {
    return this.execute("delete", `workspace/catalogs/${id}`);
  }

  /* Catalog references */
  getCatalogReferences(data) {
    return this.execute("get", "workspace/catalog-references", data);
  }
  createCatalogReferences(data) {
    return this.execute("post", "workspace/catalog-references", data);
  }
  readCatalogReferences(id, data) {
    return this.execute("get", `workspace/catalog-references/${id}`, data);
  }
  updateCatalogReferences(id, data) {
    return this.execute("put", `workspace/catalog-references/${id}`, data);
  }
  deleteCatalogReferences(id) {
    return this.execute("delete", `workspace/catalog-references/${id}`);
  }

  /* Folders */
  getFolders(data) {
    return this.execute("get", "workspace/folders", data);
  }
  createFolders(data) {
    return this.execute("post", "workspace/folders", data);
  }
  readFolders(id, data) {
    return this.execute("get", `workspace/folders/${id}`, data);
  }
  updateFolders(id, data) {
    return this.execute("put", `workspace/folders/${id}`, data);
  }
  deleteFolders(id) {
    return this.execute("delete", `workspace/folders/${id}`);
  }

  /* Machinery spare parts */
  getMachinerySpareParts(data) {
    return this.execute("get", "workspace/machinery-spare-parts", data);
  }
  createMachinerySpareParts(data) {
    return this.execute("post", "workspace/machinery-spare-parts", data);
  }
  readMachinerySpareParts(id, data) {
    return this.execute("get", `workspace/machinery-spare-parts/${id}`, data);
  }
  updateMachinerySpareParts(id, data) {
    return this.execute("put", `workspace/machinery-spare-parts/${id}`, data);
  }
  deleteMachinerySpareParts(id) {
    return this.execute("delete", `workspace/machinery-spare-parts/${id}`);
  }

  /* Parameter groups */
  getParameterGroups(data) {
    return this.execute("get", "workspace/parameter-groups", data);
  }
  createParameterGroups(data) {
    return this.execute("post", "workspace/parameter-groups", data);
  }
  readParameterGroups(id, data) {
    return this.execute("get", `workspace/parameter-groups/${id}`, data);
  }
  updateParameterGroups(id, data) {
    return this.execute("put", `workspace/parameter-groups/${id}`, data);
  }
  deleteParameterGroups(id) {
    return this.execute("delete", `workspace/parameter-groups/${id}`);
  }

  /* Specification groups */
  findSpecificationGroups(data) {
    return this.execute("get", "workspace/specification-groups", data);
  }
  createSpecificationGroup(data) {
    return this.execute("post", "workspace/specification-groups", data);
  }
  findSpecificationGroupById(id, data) {
    return this.execute("get", `workspace/specification-groups/${id}`, data);
  }
  updateSpecificationGroup(id, data) {
    return this.execute("put", `workspace/specification-groups/${id}`, data);
  }
  deleteSpecificationGroup(id, data) {
    return this.execute("delete", `workspace/specification-groups/${id}`, data);
  }

  /* Specifications */
  getSpecifications(data) {
    return this.execute("get", "workspace/specifications", data);
  }
  createSpecifications(data) {
    return this.execute("post", "workspace/specifications", data);
  }
  readSpecifications(id, data) {
    return this.execute("get", `workspace/specifications/${id}`, data);
  }
  updateSpecifications(id, data) {
    return this.execute("put", `workspace/specifications/${id}`, data);
  }
  deleteSpecifications(id) {
    return this.execute("delete", `workspace/specifications/${id}`);
  }

  /* Specification values */
  getSpecificationValues(data) {
    return this.execute("get", "workspace/specification-values", data);
  }
  createSpecificationValues(data) {
    return this.execute("post", "workspace/specification-values", data);
  }
  readSpecificationValues(id, data) {
    return this.execute("get", `workspace/specification-values/${id}`, data);
  }
  updateSpecificationValues(id, data) {
    return this.execute("put", `workspace/specification-values/${id}`, data);
  }
  deleteSpecificationValues(id) {
    return this.execute("delete", `workspace/specification-values/${id}`);
  }

  /* Specification units */
  getSpecificationUnits(data) {
    return this.execute("get", "workspace/specification-units", data);
  }
  createSpecificationUnits(data) {
    return this.execute("post", "workspace/specification-units", data);
  }
  readSpecificationUnits(id, data) {
    return this.execute("get", `workspace/specification-units/${id}`, data);
  }
  updateSpecificationUnits(id, data) {
    return this.execute("put", `workspace/specification-units/${id}`, data);
  }
  deleteSpecificationUnits(id) {
    return this.execute("delete", `workspace/specification-units/${id}`);
  }

  /* File uploader service */
  createFiles(data) {
    return this.execute("post", "upload/files/yos/equipmetry", data);
  }
  getFiles(data) {
    return this.execute("get", "files", data);
  }
  readFiles(id, data) {
    return this.execute("get", `files/${id}`, data);
  }
  updateFiles(id, data) {
    return this.execute("put", `files/${id}`, data);
  }
  deleteFiles(id) {
    return this.execute("delete", `files/${id}`);
  }

  /* Auth service: user */
  createUser(data) {
    return this.execute("post", "workspace/users", data);
  }
  getAllUsers(data) {
    return this.execute("get", "workspace/users", data);
  }
  deleteUser(id, data) {
    return this.execute("delete", `workspace/users/${id}`, data);
  }
  getUserById(id, data) {
    return this.execute("get", `workspace/users/${id}`, data);
  }
  updateUser(id, data) {
    return this.execute("put", `workspace/users/${id}`, data);
  }
  changePassword(data) {
    return this.execute("post", `workspace/users/password-change`, data);
  }
  signUpUser(data) {
    return this.execute("post", "workspace/users/sign-up", data);
  }
  activateUser(id, data) {
    return this.execute("post", `workspace/users/activate/${id}`, data);
  }
  setPassword(data) {
    return this.execute("post", `workspace/users/password-set`, data);
  }
  sendEmailConfirmation(id, data) {
    return this.execute(
      "post",
      `workspace/users/confirmation-email/${id}`,
      data
    );
  }
  resetPassword(email) {
    return this.execute("post", `workspace/users/password-reset/${email}`);
  }

  /* Auth service: identifier */
  createIdentifiers(data) {
    return this.execute("post", "workspace/identifiers", data);
  }
  deleteIdentifiers(id, data) {
    return this.execute("delete", `workspace/identifiers/${id}`, data);
  }
  getIdentifiersById(id, data) {
    return this.execute("get", `workspace/identifiers/${id}`, data);
  }
  getIdentifiersAll(data) {
    return this.execute("get", `workspace/identifiers`, data);
  }
  updateIdentifiers(id, data) {
    return this.execute("put", `workspace/identifiers/${id}`, data);
  }
  checkIdentifier(data) {
    return this.execute("post", "workspace/identifiers/check", data);
  }

  /* Auth service: token */
  tokenLogin(data, config) {
    return this.execute("post", `workspace/auth/authenticate`, data, config);
  }
  tokenResetPasswordJWT(data) {
    return this.execute("post", `workspace/auth/tokens/pwd-reset`, data);
  }

  /* Auth service: session */
  findAllSession(data) {
    return this.execute("get", "workspace/auth/sessions", data);
  }
  findSessionById(id, data) {
    return this.execute("get", `workspace/auth/sessions/${id}`, data);
  }
  terminateSession(id, data) {
    return this.execute(
      "delete",
      `workspace/auth/sessions/terminate/${id}`,
      data
    );
  }
  deleteSession(id, data) {
    return this.execute("put", `workspace/auth/sessions/${id}`, data);
  }

  /* Workspace */
  createWorkspace(data) {
    return this.execute("post", "workspace/workspaces", data);
  }
  findAllWorkspaces(data) {
    return this.execute("get", "workspace/workspaces", data);
  }
  findWorkspaceById(id, data) {
    return this.execute("get", `workspace/workspaces/${id}`, data);
  }
  deleteWorkspace(id, data) {
    return this.execute("delete", `workspace/workspaces/${id}`, data);
  }
  updateWorkspace(id, data) {
    return this.execute("put", `workspace/workspaces/${id}`, data);
  }

  /* Department */
  createDepartment(data) {
    return this.execute("post", "workspace/departments", data);
  }
  findAllDepartments(data) {
    return this.execute("get", "workspace/departments", data);
  }
  findDepartmentById(id, data) {
    return this.execute("get", `workspace/departments/${id}`, data);
  }
  deleteDepartment(id, data) {
    return this.execute("delete", `workspace/departments/${id}`, data);
  }
  updateDepartment(id, data) {
    return this.execute("put", `workspace/departments/${id}`, data);
  }
  updateDepartmentsState(id, data) {
    return this.execute("put", `workspace/departments/${id}/state`, data);
  }

  /* Position */
  createPosition(data) {
    return this.execute("post", "workspace/positions", data);
  }
  findAllPositions(data) {
    return this.execute("get", "workspace/positions", data);
  }
  findPositionById(id, data) {
    return this.execute("get", `workspace/positions/${id}`, data);
  }
  deletePosition(id, data) {
    return this.execute("delete", `workspace/positions/${id}`, data);
  }
  updatePosition(id, data) {
    return this.execute("put", `workspace/positions/${id}`, data);
  }
  updatePositionState(id, data) {
    return this.execute("put", `workspace/positions/${id}/state`, data);
  }

  /* Company */
  createCompany(data) {
    return this.execute("post", "workspace/companies", data);
  }
  findAllCompanies(data) {
    return this.execute("get", "workspace/companies", data);
  }
  findCompanyById(id, data) {
    return this.execute("get", `workspace/companies/${id}`, data);
  }
  deleteCompany(id, data) {
    return this.execute("delete", `workspace/companies/${id}`, data);
  }
  updateCompany(id, data) {
    return this.execute("put", `workspace/companies/${id}`, data);
  }
  updateCompanyState(id, data) {
    return this.execute("put", `workspace/companies/${id}/state`, data);
  }

  /* Employee */
  createEmployee(data) {
    return this.execute("post", "workspace/employees", data);
  }
  findAllEmployees(data) {
    return this.execute("get", "workspace/employees", data);
  }
  findEmployeeById(id, data) {
    return this.execute("get", `workspace/employees/${id}`, data);
  }
  updateEmployee(id, data) {
    return this.execute("put", `workspace/employees/${id}`, data);
  }
  deleteEmployee(id, data) {
    return this.execute("delete", `workspace/employees/${id}`, data);
  }
  employeeSignUp(data) {
    return this.execute("post", `workspace/employees/access/grant`, data);
  }
  updateEmployeeState(id, data) {
    return this.execute("put", `workspace/employees/${id}/state`, data);
  }
  revokeEmployeeAccess(id) {
    return this.execute("post", `workspace/employees/${id}/access/revoke`);
  }

  /* Role */
  findAllRoles(data) {
    return this.execute("get", "workspace/roles", data);
  }
  createRole(data) {
    return this.execute("post", "workspace/roles", data);
  }
  findRoleById(id, data) {
    return this.execute("get", `workspace/roles/${id}`, data);
  }
  updateRole(id, data) {
    return this.execute("put", `workspace/roles/${id}`, data);
  }
  deleteRole(id, data) {
    return this.execute("delete", `workspace/roles/${id}`, data);
  }

  /* Module */
  findModules(data) {
    return this.execute("get", "workspace/modules", data);
  }
  createModule(data) {
    return this.execute("post", "workspace/modules", data);
  }
  findModuleById(id, data) {
    return this.execute("get", `workspace/modules/${id}`, data);
  }
  updateModule(id, data) {
    return this.execute("put", `workspace/modules/${id}`, data);
  }
  deleteModule(id) {
    return this.execute("delete", `workspace/modules/${id}`);
  }

  /* Member */
  findMembers(data) {
    return this.execute("get", "workspace/workspace/members", data);
  }
  createMember(data) {
    return this.execute("post", "workspace/workspace/members", data);
  }
  findMemberById(id, data) {
    return this.execute("get", `workspace/workspace/members/${id}`, data);
  }
  updateMember(id, data) {
    return this.execute("put", `workspace/workspace/members/${id}`, data);
  }
  deleteMember(id, data) {
    return this.execute("delete", `workspace/workspace/members/${id}`, data);
  }

  /* Equipment */
  createEquipment(data) {
    return this.execute("post", "workspace/equipments", data);
  }
  findAllEquipments(data) {
    return this.execute("get", "workspace/equipments", data);
  }
  getEquipmentsCount(data) {
    return this.execute("get", "workspace/equipments/count", data);
  }
  findAllPreviewEquipments(data) {
    return this.execute("get", "workspace/equipments/preview", data);
  }
  findEquipmentById(id, data) {
    return this.execute("get", `workspace/equipments/${id}`, data);
  }
  findEquipmentByIdDetailed(id, data) {
    return this.execute("get", `workspace/equipments/${id}/detailed`, data);
  }
  findEquipmentsCatalogs(id, data) {
    return this.execute("get", `workspace/equipments/${id}/catalogs`, data);
  }
  updateEquipment(id, data) {
    return this.execute("put", `workspace/equipments/${id}`, data);
  }
  postHistoryEquipments(data) {
    return this.execute("post", `workspace/history/equipments`, data);
  }
  getHistoryEquipments(data) {
    return this.execute("get", `workspace/history/equipments`, data);
  }
  updateEquipmentState(id, data) {
    return this.execute("put", `workspace/equipments/${id}/state`, data);
  }
  deleteEquipment(id, data) {
    return this.execute("delete", `workspace/equipments/${id}`, data);
  }
  findFines(data) {
    return this.execute("get", `workspace/equipments/fines`, data);
  }
  findFineProtocol(id, data) {
    return this.execute(
      "get",
      `workspace/equipments/fines/protocol/${id}`,
      data
    );
  }
  findEquipmentsOverview(data) {
    return this.execute("get", `workspace/equipments/overview`, data);
  }

  /* Equipment Condition */
  createEquipCondition(data) {
    return this.execute("post", "workspace/equipments/conditions", data);
  }
  findAllEquipCondition(data) {
    return this.execute("get", "workspace/equipments/conditions", data);
  }
  findEquipConditionById(id, data) {
    return this.execute("get", `workspace/equipments/conditions/${id}`, data);
  }
  updateEquipCondition(id, data) {
    return this.execute("put", `workspace/equipments/conditions/${id}`, data);
  }
  deleteEquipCondition(id) {
    return this.execute("delete", `workspace/equipments/conditions/${id}`);
  }
  updateCronTimerValue(data) {
    return this.execute("put", "workspace/equipments/conditions/timer", data);
  }
  findCronTimerValue(data) {
    return this.execute("get", "workspace/equipments/conditions/timer", data);
  }

  /* Mileage */
  createMileage(data) {
    return this.execute("post", "workspace/mileages", data);
  }
  findAllMileages(data) {
    return this.execute("get", "workspace/mileages", data);
  }
  findLatestMileages(data) {
    return this.execute("get", "workspace/mileages/latest", data);
  }
  findMileageById(id, data) {
    return this.execute("get", `workspace/mileages/${id}`, data);
  }
  deleteMileageById(id, data) {
    return this.execute("delete", `workspace/mileages/${id}`, data);
  }
  updateMileage(id, data) {
    return this.execute("put", `workspace/mileages/${id}`, data);
  }

  /* Med Condition */
  createMedCondition(data) {
    return this.execute("post", "workspace/employees/conditions", data);
  }
  findAllMedCondition(data) {
    return this.execute("get", "workspace/employees/conditions", data);
  }
  findMedConditionById(id, data) {
    return this.execute("get", `workspace/employees/conditions/${id}`, data);
  }
  updateMedCondition(id, data) {
    return this.execute("put", `workspace/employees/conditions/${id}`, data);
  }
  deleteMedCondition(id, data) {
    return this.execute("delete", `workspace/employees/conditions/${id}`, data);
  }
  updateMedConditionCronTimerValue(data) {
    return this.execute("put", "workspace/employees/conditions/timer", data);
  }
  findMedConditionCronTimerValue(data) {
    return this.execute("get", "workspace/employees/conditions/timer", data);
  }

  /* Exploitation Schedules */
  createSchedule(data) {
    return this.execute("post", "workspace/exploitation/schedules", data);
  }
  findAllSchedules(data) {
    return this.execute("get", "workspace/exploitation/schedules", data);
  }
  findByIdSchedule(id, data) {
    return this.execute("get", `workspace/exploitation/schedules/${id}`, data);
  }
  updateSchedule(id, data) {
    return this.execute("put", `workspace/exploitation/schedules/${id}`, data);
  }
  deleteSchedule(id) {
    return this.execute("delete", `workspace/exploitation/schedules/${id}`);
  }

  /* Work Type */
  createWorkType(data) {
    return this.execute("post", "workspace/exploitation/work-types", data);
  }
  findAllWorkType(data) {
    return this.execute("get", "workspace/exploitation/work-types", data);
  }
  findByIdWorkType(id, data) {
    return this.execute("get", `workspace/exploitation/work-types/${id}`, data);
  }
  deleteWorkType(id) {
    return this.execute("delete", `workspace/exploitation/work-types/${id}`);
  }
  updateWorkType(id, data) {
    return this.execute("put", `workspace/exploitation/work-types/${id}`, data);
  }
  updateWorkTypeState(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/work-types/${id}/state`,
      data
    );
  }

  /* Approver */
  findAllApprover(data) {
    return this.execute("get", "workspace/exploitation/approvers", data);
  }
  deleteApprover(id) {
    return this.execute("delete", `workspace/exploitation/approvers/${id}`);
  }

  /* Orderer group */
  createOrdererGroups(data) {
    return this.execute("post", "workspace/exploitation/orderer-groups", data);
  }
  findAllOrdererGroups(data) {
    return this.execute("get", "workspace/exploitation/orderer-groups", data);
  }
  findByIdOrdererGroup(id, data) {
    return this.execute(
      "get",
      `workspace/exploitation/orderer-groups/${id}`,
      data
    );
  }
  updateOrdererGroup(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/orderer-groups/${id}`,
      data
    );
  }
  deleteOrdererGroup(id) {
    return this.execute(
      "delete",
      `workspace/exploitation/orderer-groups/${id}`
    );
  }

  /* Order */
  createOrderer(data) {
    return this.execute("post", "workspace/exploitation/orderers", data);
  }
  findAllOrderers(data) {
    return this.execute("get", "workspace/exploitation/orderers", data);
  }
  findByIdOrderer(id, data) {
    return this.execute("get", `workspace/exploitation/orderers/${id}`, data);
  }
  findByMemberIdOrderer(id, data) {
    return this.execute(
      "get",
      `workspace/exploitation/orderers/by-member/${id}`,
      data
    );
  }
  updateOrderer(id, data) {
    return this.execute("put", `workspace/exploitation/orderers/${id}`, data);
  }
  updateOrdererState(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/orderers/state?id=${id}`,
      data
    );
  }

  /* Route */
  createRoute(data) {
    return this.execute("post", "workspace/exploitation/routes", data);
  }
  findAllRoutes(data) {
    return this.execute("get", "workspace/exploitation/routes", data);
  }
  findByIdRoute(id, data) {
    return this.execute("get", `workspace/exploitation/routes/${id}`, data);
  }
  updateRoute(id, data) {
    return this.execute("put", `workspace/exploitation/routes/${id}`, data);
  }
  updateRouteState(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/routes/${id}/state`,
      data
    );
  }

  /* Order */
  createOrder(data) {
    return this.execute("post", "workspace/exploitation/orders", data);
  }
  findRelativeEquipmentsOrders(id, data) {
    return this.execute(
      "get",
      `workspace/exploitation/orders/${id}/equipments`,
      data
    );
  }
  setEquipmentsOrder(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/orders/${id}/equipments/set`,
      data
    );
  }
  sendToApproversOrder(id, data) {
    return this.execute(
      "post",
      `workspace/exploitation/orders/${id}/approvers/approval`,
      data
    );
  }
  sendToExecutorsOrder(id, data) {
    return this.execute(
      "post",
      `workspace/exploitation/orders/${id}/executors/approval`,
      data
    );
  }

  updateFinishConfirmation(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/orders/${id}/orderers/confirmation`,
      data
    );
  }

  updateExecutorVerdictStatusOrder(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/orders/${id}/executors/status`,
      data
    );
  }
  findAllOrders(data) {
    return this.execute("get", "workspace/exploitation/orders", data);
  }
  findAllDetailedOrders(data) {
    return this.execute("get", "workspace/exploitation/orders/preview", data);
  }
  findByIdOrder(id, data) {
    return this.execute("get", `workspace/exploitation/orders/${id}`, data);
  }
  updateOrderStatus(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/orders/${id}/status`,
      data
    );
  }
  updateOrder(id, data) {
    return this.execute("put", `workspace/exploitation/orders/${id}`, data);
  }

  /* Approvement */
  updateApprovementsStatus(id, data) {
    return this.execute(
      "put",
      `workspace/exploitation/approvements/${id}/approver/status`,
      data
    );
  }

  /* Tickets */
  createTicket(data) {
    return this.execute("post", "workspace/operation/tickets", data);
  }
  createTicketDefectBased(data) {
    return this.execute(
      "post",
      "workspace/operation/tickets/defect-based",
      data
    );
  }
  updateTicket(id, data) {
    return this.execute("put", `workspace/operation/tickets/${id}`, data);
  }
  deleteTicket(id) {
    return this.execute("delete", `workspace/operation/tickets/${id}`);
  }
  findAllTicket(data) {
    return this.execute("get", "workspace/operation/tickets", data);
  }
  findByIdTicket(id, data) {
    return this.execute("get", `workspace/operation/tickets/${id}`, data);
  }
  findByIdTicketDetailed(id, data) {
    return this.execute(
      "get",
      `workspace/operation/tickets/${id}/detailed`,
      data
    );
  }
  updateTicketStatus(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/status`,
      data
    );
  }
  createTicketComment(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/comment`,
      data
    );
  }
  updateTicketTodo(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/todo/statuses`,
      data
    );
  }
  deleteByIdTicket(id, data) {
    return this.execute("delete", `workspace/operation/tickets/${id}`, data);
  }
  setApproversTicket(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/approvers/set`,
      data
    );
  }
  setExecutorsTicket(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/executors/set`,
      data
    );
  }
  setInspectorsTicket(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/inspectors/set`,
      data
    );
  }
  sendToApprovementTicket(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/approvement/start`,
      data
    );
  }
  sendToExecutorApprovalTicket(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/executors/approvement/start`,
      data
    );
  }
  updateApproversVerdictTicket(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/approvers/verdict`,
      data
    );
  }
  updateExecutorsVerdictTicket(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/executors/verdict`,
      data
    );
  }
  updateInspectorsVerdictTicket(id, data) {
    return this.execute(
      "put",
      `workspace/operation/tickets/${id}/inspectors/verdict`,
      data
    );
  }
  findAllPreviewTicket(data) {
    return this.execute("get", "workspace/operation/tickets/preview", data);
  }
  getTicketsCount(data) {
    return this.execute("get", "workspace/operation/tickets/count", data);
  }
  findByIdRawTicket(id, data) {
    return this.execute("get", `workspace/operation/tickets/${id}/raw`, data);
  }
  updateTicketStart(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/start`,
      data
    );
  }
  updateTicketPause(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/pause`,
      data
    );
  }
  updateTicketFinish(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/finish`,
      data
    );
  }
  updateTicketFinalize(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/finalize`,
      data
    );
  }
  updateTicketReject(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/reject`,
      data
    );
  }
  updateTicketComment(id, data) {
    return this.execute(
      "post",
      `workspace/operation/tickets/${id}/comment`,
      data
    );
  }

  /* Defect */
  /* createDefect(data) {
    return this.execute("post", "workspace/operation/defects", data);
  }
  updateDefect(id, data) {
    return this.execute("put", `workspace/operation/defects/${id}`, data);
  }
  updateDefectStatus(id, data) {
    return this.execute(
      "put",
      `workspace/operation/defects/${id}/status`,
      data
    );
  }
  updateMechanicVerdictDefect(id, data) {
    return this.execute(
      "put",
      `workspace/operation/defects/${id}/mechanics/verdict`,
      data
    );
  }
  createDefectComment(id, data) {
    return this.execute(
      "post",
      `workspace/operation/defects/${id}/comment`,
      data
    );
  }
  findAllDefect(data) {
    return this.execute("get", "workspace/operation/defects", data);
  }
  findByIdDefect(id, data) {
    return this.execute("get", `workspace/operation/defects/${id}`, data);
  }
  deleteDefect(id, data) {
    return this.execute("delete", `workspace/operation/defects/${id}`, data);
  }
  findAllRawDefect(data) {
    return this.execute("get", "workspace/operation/defects/raw", data);
  }
  findByIdRawDefect(id, data) {
    return this.execute("get", `workspace/operation/defects/${id}/raw`, data);
  }*/

  /* Defect new */
  getDefectsCount(data) {
    return this.execute("get", "workspace/defects/count", data);
  }
  createDefect(data) {
    return this.execute("post", "workspace/defects", data);
  }
  updateDefect(id, data) {
    return this.execute("put", `workspace/defects/${id}`, data);
  }
  findAllDefect(data) {
    return this.execute("get", "workspace/defects", data);
  }
  previewDefect(data) {
    return this.execute("get", "workspace/defects/preview", data);
  }
  findByIdDefect(id, data) {
    return this.execute("get", `workspace/defects/${id}`, data);
  }
  deleteDefect(id, data) {
    return this.execute("delete", `workspace/defects/${id}`, data);
  }

  /* Defect categories */
  createDefectCategory(data) {
    return this.execute("post", "workspace/defect/categories", data);
  }
  findAllDefectCategory(data) {
    return this.execute("get", "workspace/defect/categories", data);
  }
  findByIdDefectCategory(id, data) {
    return this.execute("get", `workspace/defect/categories/${id}`, data);
  }
  updateDefectCategory(id, data) {
    return this.execute("put", `workspace/defect/categories/${id}`, data);
  }
  deleteDefectCategory(id) {
    return this.execute("delete", `workspace/defect/categories/${id}`);
  }

  /* Expenses */
  createExpense(data) {
    return this.execute("post", "workspace/accounting/expenses", data);
  }
  findAllExpense(data) {
    return this.execute("get", "workspace/accounting/expenses", data);
  }
  findByIdExpense(id, data) {
    return this.execute("get", `workspace/accounting/expenses/${id}`, data);
  }
  updateExpense(id, data) {
    return this.execute("put", `workspace/accounting/expenses/${id}`, data);
  }
  deleteExpense(id, data) {
    return this.execute("delete", `workspace/accounting/expenses/${id}`, data);
  }
  updateExpenseStatus(id, data) {
    return this.execute(
      "put",
      `workspace/accounting/expenses/${id}/status`,
      data
    );
  }

  /* Expenses Type */
  createExpenseType(data) {
    return this.execute("post", "workspace/accounting/expenses/types", data);
  }
  findAllExpenseType(data) {
    return this.execute("get", "workspace/accounting/expenses/types", data);
  }
  findByIdExpenseType(id, data) {
    return this.execute(
      "get",
      `workspace/accounting/expenses/types/${id}`,
      data
    );
  }
  updateExpenseType(id, data) {
    return this.execute(
      "put",
      `workspace/accounting/expenses/types/${id}`,
      data
    );
  }
  deleteExpenseType(id, data) {
    return this.execute(
      "delete",
      `workspace/accounting/expenses/types/${id}`,
      data
    );
  }

  /* Expense unit */
  createExpenseUnit(data) {
    return this.execute("post", "workspace/accounting/expenses/units", data);
  }
  findAllExpenseUnit(data) {
    return this.execute("get", "workspace/accounting/expenses/units", data);
  }
  findByIdExpenseUnit(id, data) {
    return this.execute(
      "get",
      `workspace/accounting/expenses/units/${id}`,
      data
    );
  }
  updateExpenseUnit(id, data) {
    return this.execute(
      "put",
      `workspace/accounting/expenses/units/${id}`,
      data
    );
  }
  deleteExpenseUnit(id) {
    return this.execute("delete", `workspace/accounting/expenses/units/${id}`);
  }

  /* Handbook column */
  findAllHandbookColumns(data) {
    return this.execute("get", "workspace/equipment/columns", data);
  }
  createHandbookColumn(data) {
    return this.execute("post", "workspace/equipment/columns", data);
  }
  findByIdHandbookColumn(id, data) {
    return this.execute("get", `workspace/equipment/columns/${id}`, data);
  }
  updateHandbookColumn(id, data) {
    return this.execute("put", `workspace/equipment/columns/${id}`, data);
  }
  deleteHandbookColumn(id) {
    return this.execute("delete", `workspace/equipment/columns/${id}`, null);
  }

  /* Handbook object */
  findAllHandbookObjects(data) {
    return this.execute("get", "workspace/equipment/sectors", data);
  }
  createHandbookObject(data) {
    return this.execute("post", "workspace/equipment/sectors", data);
  }
  findByIdHandbookObject(id, data) {
    return this.execute("get", `workspace/equipment/sectors/${id}`, data);
  }
  updateHandbookObject(id, data) {
    return this.execute("put", `workspace/equipment/sectors/${id}`, data);
  }
  deleteHandbookObject(id) {
    return this.execute("delete", `workspace/equipment/sectors/${id}`, null);
  }

  /* Service-repairs */
  createServiceTask(data) {
    return this.execute("post", "workspace/service/tasks", data);
  }
  getServiceWorksCount(data) {
    return this.execute("get", "workspace/service/tasks/count", data);
  }
  findAllServiceTasks(data) {
    return this.execute("get", "workspace/service/tasks", data);
  }
  findByIdServiceTask(id, data) {
    return this.execute("get", `workspace/service/tasks/${id}`, data);
  }
  updateServiceTask(id, data) {
    return this.execute("put", `workspace/service/tasks/${id}`, data);
  }
  deleteServiceTask(id) {
    return this.execute("delete", `workspace/service/tasks/${id}`);
  }
  createServiceProgram(data) {
    return this.execute("post", "workspace/service/programs", data);
  }
  findAllServicePrograms(data) {
    return this.execute("get", "workspace/service/programs", data);
  }
  getServiceProgramsCount(data) {
    return this.execute("get", "workspace/service/programs/count", data);
  }
  findByIdServiceProgram(id, data) {
    return this.execute("get", `workspace/service/programs/${id}`, data);
  }
  updateServiceProgram(id, data) {
    return this.execute("put", `workspace/service/programs/${id}`, data);
  }
  deleteServiceProgram(id) {
    return this.execute("delete", `workspace/service/programs/${id}`);
  }
  createServiceReferences(data) {
    return this.execute("post", "workspace/service/references", data);
  }
  findAllServiceReferences(data) {
    return this.execute("get", "workspace/service/references", data);
  }
  findByIdServiceReferences(id, data) {
    return this.execute("get", `workspace/service/references/${id}`, data);
  }
  updateServiceReferences(id, data) {
    return this.execute("put", `workspace/service/references/${id}`, data);
  }
  deleteServiceReferences(id) {
    return this.execute("delete", `workspace/service/references/${id}`);
  }
  createServiceMaintenances(data) {
    return this.execute("post", "workspace/service/maintenances", data);
  }
  findAllServiceMaintenances(data) {
    return this.execute("get", "workspace/service/maintenances", data);
  }
  findAllServiceMaintenancesDetailed(data) {
    return this.execute("get", "workspace/service/maintenances/detailed", data);
  }
  findAllServiceMaintenancesPreview(data) {
    return this.execute("get", "workspace/service/maintenances/preview", data);
  }
  findAllServiceMaintenancesPrePreview(data) {
    return this.execute(
      "get",
      "workspace/service/maintenances/pre/preview",
      data
    );
  }
  findByIdServiceMaintenance(id, data) {
    return this.execute("get", `workspace/service/maintenances/${id}`, data);
  }
  findByIdServiceMaintenanceDetailed(id, data) {
    return this.execute(
      "get",
      `workspace/service/maintenances/${id}/detailed`,
      data
    );
  }
  updateServiceMaintenances(id, data) {
    return this.execute("put", `workspace/service/maintenances/${id}`, data);
  }
  deleteServiceMaintenances(id) {
    return this.execute("delete", `workspace/service/maintenances/${id}`);
  }

  /* Service storehouse */
  createStorehouseOrders(data) {
    return this.execute("post", "workspace/storehouse/orders", data);
  }
  setOutOfStockItems(id, data) {
    return this.execute(
      "post",
      `workspace/storehouse/orders/${id}/items/out-of-stock`,
      data
    );
  }
  sendToPurchase(id, data) {
    return this.execute(
      "post",
      `workspace/storehouse/orders/${id}/purchase-stage`,
      data
    );
  }
  updateOutOfStockItems(id, data) {
    return this.execute(
      "put",
      `workspace/storehouse/orders/${id}/items/out-of-stock`,
      data
    );
  }
  findAllStorehouseOrders(data) {
    return this.execute("get", "workspace/storehouse/orders", data);
  }
  findAllStorehouseOrdersPreview(data) {
    return this.execute("get", "workspace/storehouse/orders/preview", data);
  }
  getStorehouseOrdersCount(data) {
    return this.execute("get", "workspace/storehouse/orders/count", data);
  }
  findByIdStorehouseOrder(id, data) {
    return this.execute("get", `workspace/storehouse/orders/${id}`, data);
  }
  updateStorehouseOrder(id, data) {
    return this.execute("put", `workspace/storehouse/orders/${id}`, data);
  }
  findByIdStorehouseOrderDetailed(id, data) {
    return this.execute(
      "get",
      `workspace/storehouse/orders/${id}/detailed`,
      data
    );
  }
  deleteStorehouseOrder(id) {
    return this.execute("delete", `workspace/storehouse/orders/${id}`);
  }

  createStorehouseCostCenter(data) {
    return this.execute("post", "workspace/storehouse/cost-center", data);
  }
  findAllStorehouseCostCenter(data) {
    return this.execute("get", "workspace/storehouse/cost-center", data);
  }
  findByIdStorehouseCostCenter(id, data) {
    return this.execute("get", `workspace/storehouse/cost-center/${id}`, data);
  }
  updateStorehouseCostCenter(id, data) {
    return this.execute("put", `workspace/storehouse/cost-center/${id}`, data);
  }
  deleteStorehouseCostCenter(id) {
    return this.execute("delete", `workspace/storehouse/cost-center/${id}`);
  }

  /* Projects */
  createProject(data) {
    return this.execute("post", "workspace/projects", data);
  }
  findAllProjects(data) {
    return this.execute("get", "workspace/projects", data);
  }
  getProjectsCount(data) {
    return this.execute("get", "workspace/projects/count", data);
  }
  updateProject(id, data) {
    return this.execute("put", `workspace/projects/${id}`, data);
  }
  deleteProject(id) {
    return this.execute("delete", `workspace/projects/${id}`, null);
  }

  /* PaymentMethod */
  createPaymentMethod(data) {
    return this.execute("post", "workspace/payment-method", data);
  }
  getPaymentMethodsCount(data) {
    return this.execute("get", "workspace/payment-method/count", data);
  }
  findAllPaymentMethods(data) {
    return this.execute("get", "workspace/payment-method", data);
  }
  updatePaymentMethod(id, data) {
    return this.execute("put", `workspace/payment-method/${id}`, data);
  }
  deletePaymentMethod(id) {
    return this.execute("delete", `workspace/payment-method/${id}`, null);
  }

  /* Customer */
  createCustomer(data) {
    return this.execute("post", "workspace/customer", data);
  }
  getCustomersCount(data) {
    return this.execute("get", "workspace/customer/count", data);
  }
  findAllCustomers(data) {
    return this.execute("get", "workspace/customer", data);
  }
  updateCustomer(id, data) {
    return this.execute("put", `workspace/customer/${id}`, data);
  }
  deleteCustomer(id) {
    return this.execute("delete", `workspace/customer/${id}`, null);
  }

  /* EquipmentRelease */
  createEquipmentRelease(data) {
    return this.execute("post", "workspace/equipment-release", data);
  }
  getEquipmentReleasesCount(data) {
    return this.execute("get", "workspace/equipment-release/count", data);
  }
  findAllEquipmentReleases(data) {
    return this.execute("get", "workspace/equipment-release", data);
  }
  getEquipmentRelease(id, data) {
    return this.execute("get", `workspace/equipment-release/${id}`, data);
  }
  updateEquipmentRelease(id, data) {
    return this.execute("put", `workspace/equipment-release/${id}`, data);
  }
  deleteEquipmentRelease(id) {
    return this.execute("delete", `workspace/equipment-release/${id}`, null);
  }

  /* ExpenseJournal */
  findAllExpenses(data) {
    return this.execute("get", "workspace/expense-journal", data);
  }
  getExpensesCount(data) {
    return this.execute("get", "workspace/expense-journal/count", data);
  }
}
