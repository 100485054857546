import moment from "moment";
moment.locale("ru");

export default {
  data: () => ({
    selectGlobal: {
      roles: [{ value: "manager", text: "Менеджер" }],
    },
    formRules: {
      required: (value) => !!value || "Обязательное поле.",
      requiredRadio: (value) => value !== null || "Обязательное поле.",
      requiredList: (value) =>
        (value && value.length > 0) || "Обязательное поле.",
      counter6: (value) =>
        (value && value.length >= 6) || "Минимум 6 символов.",
      counter8: (value) =>
        (value && value.length >= 8) || "Минимум 8 символов.",
      bin: (value) =>
        (value && value.length === 12) || "Должен быть 12 символов.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Неккоректный формат электронной почты.";
      },
      number: (value) => {
        const pattern = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        return pattern.test(value) || "Некорректное число";
      },
      integer: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Некорректное число";
      },
    },
    noticeText: {
      errorOnFill: `Некорректные данные`,
      tryAgain: `Ошибка! Пожалуйста, повторите еще раз`,
      fillAllFields: "Заполните все необходимые поля",
      category: {
        create: "Категория успешно созданы",
        update: "Категория успешно отредактирован",
        delete: "Категория успешно удален",
      },
      brand: {
        create: "Бренд успешно созданы",
        update: "Бренд успешно отредактирован",
        delete: "Бренд успешно удален",
      },
      model: {
        create: "Модель успешно созданы",
        update: "Модель успешно отредактирован",
        delete: "Модель успешно удален",
      },
      parameter: {
        create: "Параметр успешно созданы",
        update: "Параметр успешно отредактирован",
        delete: "Параметр успешно удален",
      },
      catalog: {
        create: "Каталог успешно созданы",
        update: "Каталог успешно отредактирован",
        delete: "Каталог успешно удален",
      },
      group: {
        create: "Группа успешно созданы",
        update: "Группа успешно отредактирован",
        delete: "Группа успешно удален",
      },
    },
    dataTableOptions: {
      itemsPerPage: 10,
      page: 1,
      pageCount: 1,
      footerProps: {
        itemsPerPageOptions: [5, 10, 50, 100, -1],
      },
    },
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    session() {
      return this.$store.getters["user/session"];
    },
    userRole() {
      return this.$store.getters["user/userRole"];
    },
    workspace() {
      return this.$store.getters["workspace/workspace"];
    },
  },
  filters: {
    numLocaleString(val, digits) {
      if (val !== null) {
        if (typeof val === "number") {
          return digits === undefined
            ? val.toLocaleString("ru-RU")
            : val.toLocaleString("ru-RU", {
                maximumFractionDigits: digits,
              });
        } else {
          if (!isNaN(val)) {
            return digits === undefined
              ? parseFloat(val).toLocaleString("ru-RU")
              : parseFloat(val).toLocaleString("ru-RU", {
                  maximumFractionDigits: digits,
                });
          }
        }
      }
      return val;
    },
    dateParsedFull(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : null;
    },
    dateParsedDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : null;
    },
    dateParsedDates(dateList) {
      let r = [];
      dateList.forEach((d) => {
        r.push(moment(d).format("DD.MM.YYYY"));
      });
      return r.join(" - ");
    },
  },
  methods: {
    dateDiff(dateStart, dateEnd) {
      let prefix = "Осталось";
      let suffix = "дней";
      let diff = 0;
      let color = "#000000";
      const today = moment().format("YYYY-MM-DD");
      if (dateEnd) {
        diff = moment(dateEnd).diff(moment(today), "days");
        if (dateEnd < today) {
          prefix = "Прошло";
        }
      } else if (dateStart) {
        diff = moment(today).diff(moment(dateStart), "days");
        if (dateStart > today) {
          prefix = "Еще есть";
        } else {
          prefix = "Прошло";
        }
      }

      diff = Math.abs(diff);
      if (diff === 1) {
        suffix = "день";
      } else if ([0, 2, 3, 4].includes(diff)) {
        suffix = "дня";
      } else {
        suffix = "дней";
      }

      if (prefix === "Еще есть") {
        color = "#34C759";
      } else if (prefix === "Прошло") {
        color = "#FF3B30";
      } else if (prefix === "Осталось") {
        if (diff <= 5) {
          color = "#FF3B30";
        } else if (diff > 5 && diff <= 10) {
          color = "#FF9500";
        } else {
          color = "#34C759";
        }
      }

      return {
        prefix: prefix,
        diff: diff,
        suffix: suffix,
        color: color,
        text: [prefix, diff, suffix].join(" "),
      };
    },
    dateAdd(date, days) {
      return date ? moment(date).add(days, "days").format("YYYY-MM-DD") : null;
    },
    hasPermission(menu, method = null, group = false) {
      let bool = false,
        crud = {
          1: ["delete"],
          2: ["update"],
          3: ["update", "delete"],
          4: ["read"],
          5: ["read", "delete"],
          6: ["read", "update"],
          7: ["read", "update", "delete"],
          8: ["create"],
          9: ["create", "delete"],
          10: ["create", "update"],
          11: ["create", "update", "delete"],
          12: ["create", "read"],
          13: ["create", "read", "delete"],
          14: ["create", "read", "update"],
          15: ["create", "read", "update", "delete"],
        };
      if (
        menu &&
        this.session &&
        Object.values(this.session.acl).length !== 0
      ) {
        for (const [key, data] of Object.entries(this.session.acl)) {
          if (
            (`/api/v1/workspace/${menu}` === key && !group) ||
            (key.indexOf(menu) !== -1 && group)
          ) {
            for (let i = 0; i < data.length; i++) {
              if (this.workspace.id in data[i]) {
                let value = data[i][this.workspace.id];
                if (method === null) {
                  bool = true;
                  break;
                } else if (value in crud && crud[value].includes(method)) {
                  bool = true;
                  break;
                }
              }
            }
            break;
          }
        }
      } else if (this.session && Object.values(this.session.acl).length === 0) {
        bool = false;
      } else {
        bool = true;
      }
      return bool;
    },
    millisecondsToHoursMinutes(milliseconds) {
      return {
        hours: parseFloat(milliseconds / 3600000.0).toFixed(3),
        minutes: parseFloat(milliseconds / 60000.0).toFixed(3),
      };
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isInteger($event) {
      if (
        $event.charCode === 0 ||
        /\d/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      }
      $event.preventDefault();
    },
    downloadFile(file) {
      if (typeof file === "object") {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        window.open(file);
      }
    },
  },
};
