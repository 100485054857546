export default {
  namespaced: true,
  state: {
    pagination: {
      itemsPerPage: 10,
      page: 1,
      pageCount: 1,
      footerProps: {
        itemsPerPageOptions: [5, 10, 50, 100],
      },
    },
    filter: {
      search: null,
    },
  },
  mutations: {
    setFilter(state, payload) {
      state.filter = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    clearPagination(state) {
      state.pagination = {
        itemsPerPage: 10,
        page: 1,
        pageCount: 1,
        footerProps: {
          itemsPerPageOptions: [5, 10, 50, 100],
        },
      };
    },
  },
  getters: {
    pagination(state) {
      return state["pagination"];
    },
    filter(state) {
      return state["filter"];
    },
  },
  actions: {
    setPagination({ commit }, payload) {
      commit("setPagination", payload);
    },
    setFilter({ commit }, payload) {
      commit("setFilter", payload);
    },
    clearPagination({ commit }) {
      commit("clearPagination");
    },
  },
};
